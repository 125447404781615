.ant-carousel .slick-slide {
  text-align: center;
  /* height: 400px; */
  /* height: 40vh; */
  height: 45vh;
  /* line-height: 160px; */
  /* background: #364d79; */
  overflow: hidden;
}
.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #FFB4A4;
}
@media only screen and (max-width: 1024px) {
  .ant-carousel .slick-slide {
    height: 38vh;
  }
}
@media only screen and (max-width: 768px) {
  .ant-carousel .slick-slide {
    height: 30vh;
  }
}
@media only screen and (max-width: 425px) {
  .ant-carousel .slick-slide {
    height: 22vh;
  }
}
