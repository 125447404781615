@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
    margin: 0 0 0 0;
  /* margin: 200px 0 0 0; */
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: "Lato", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-carousel .slick-slide {
  text-align: center;
  /* height: 400px; */
  /* height: 40vh; */
  height: 45vh;
  /* line-height: 160px; */
  /* background: #364d79; */
  overflow: hidden;
}
.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #FFB4A4;
}
@media only screen and (max-width: 1024px) {
  .ant-carousel .slick-slide {
    height: 38vh;
  }
}
@media only screen and (max-width: 768px) {
  .ant-carousel .slick-slide {
    height: 30vh;
  }
}
@media only screen and (max-width: 425px) {
  .ant-carousel .slick-slide {
    height: 22vh;
  }
}

