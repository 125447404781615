@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
    margin: 0 0 0 0;
  /* margin: 200px 0 0 0; */
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: "Lato", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
